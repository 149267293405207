<!-- Type Text -->
<mat-form-field *ngIf="type === 'text'" class="app-input" [ngClass]="{'read-only': readonly}" [floatLabel]="readonly ? 'always' : 'auto'" [appearance]="appearance">
    <mat-label>{{label}}</mat-label>
    <mat-icon class="app-input-suffix" *ngIf="matSuffix" matSuffix>{{matSuffix}}</mat-icon>
    <input matInput placeholder="{{placeholder}}" [maxlength]="maxLength" [minlength]="minLength" [formControl]="formControl"
        required="{{required}}" type="{{type}}" readonly="{{readonly}}" [ngModel]="property"
        (ngModelChange)="changeModel($event)">
    <mat-hint align="start"><strong>{{hintStart}}</strong> </mat-hint>
    <mat-hint align="end">{{hintEnd}}</mat-hint>
    <mat-error *ngIf="formControl.hasError('required')">
         {{ 'field_required' | translate }}
    </mat-error>
    <mat-error *ngIf="formControl.hasError('minlength') && !formControl.hasError('required')">
        {{ 'Min ' + minLength + ' ' +
        ('characters' | translate) }}
    </mat-error>
    <mat-error *ngIf="formControl.hasError('maxlength') && !formControl.hasError('required')">
        {{ 'Max ' + maxLength + ' ' +
        ('characters' | translate)}}
    </mat-error>
</mat-form-field>

<!-- Type Email -->
<mat-form-field *ngIf="type === 'email'" class="app-input" appearance="outline">
    <mat-label>{{label}}</mat-label>
    <input matInput placeholder="{{placeholder}}" maxlength="255" [formControl]="formControl"
        required="{{required}}" type="{{type}}" readonly="{{readonly}}" [ngModel]="property"
        (ngModelChange)="changeModel($event)">
    <mat-hint align="start"><strong>{{hintStart}}</strong> </mat-hint>
    <mat-hint align="end">{{hintEnd}}</mat-hint>
    <mat-error *ngIf="(formControl.hasError('email') || formControl.hasError('pattern')) && !formControl.hasError('required')">
        {{ 'please_valid_email' | translate }}
    </mat-error>
    <mat-error *ngIf="formControl.hasError('required')">
         {{ 'field_required' | translate }}
    </mat-error>
</mat-form-field>

<!-- Type Password -->
<mat-form-field *ngIf="type === 'password'" class="app-input" appearance="outline">
    <mat-label>{{label}}</mat-label>
    <input matInput placeholder="{{placeholder}}" [formControl]="formControl" required="{{required}}"
        [type]="hidePassword ? 'password' : 'text'" readonly="{{readonly}}" [ngModel]="property" (ngModelChange)="changeModel($event)">
    <button type="button" matSuffix mat-icon-button (click)="hidePassword = !hidePassword">
        <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
    </button>
    <mat-hint align="start"><strong>{{hintStart}}</strong> </mat-hint>
    <mat-hint align="end">{{hintEnd}}</mat-hint>
    <mat-error
        *ngIf="(formControl.hasError('minlength') || formControl.hasError('maxlength') || formControl.hasError('pattern')) && !formControl.hasError('required')">
        {{ (disablePasswordControl ? 'password_rules' : 'password_rules_strong') | translate }}
    </mat-error>
    <mat-error *ngIf="formControl.hasError('required')">
         {{ 'field_required' | translate }}
    </mat-error>
</mat-form-field>

<!-- Type Number -->
<mat-form-field *ngIf="type === 'number'" class="app-input" appearance="outline">
    <mat-label>{{label}}</mat-label>
    <mat-icon class="app-input-suffix" *ngIf="matSuffix" matSuffix>{{matSuffix}}</mat-icon>
    <input matInput placeholder="{{placeholder}}" maxlength="255" [formControl]="formControl" min="0" (keypress)="validateNumberInput($event)"
        required="{{required}}" type="{{type}}" readonly="{{readonly}}" [ngModel]="property"
        (ngModelChange)="changeModel($event)">
    <mat-hint align="start"><strong>{{hintStart}}</strong> </mat-hint>
    <mat-hint align="end">{{hintEnd}}</mat-hint>
    <mat-error *ngIf="formControl.hasError('pattern')">
        {{ 'only_numbers_allowed' | translate }}
    </mat-error>
    <mat-error *ngIf="formControl.hasError('required')">
         {{ 'field_required' | translate }}
    </mat-error>
</mat-form-field>

<!-- Type Decimal -->
<mat-form-field *ngIf="type === 'decimal'" class="app-input" appearance="outline">
    <mat-label>{{label}}</mat-label>
    <mat-icon class="app-input-suffix" *ngIf="matSuffix" matSuffix>{{matSuffix}}</mat-icon>
    <input matInput placeholder="{{placeholder}}" maxlength="255" [formControl]="formControl" (keypress)="validateDecimalInput($event)"
        required="{{required}}" type="'number'" readonly="{{readonly}}" [ngModel]="property"
        (ngModelChange)="changeModel($event)">
    <mat-hint align="start"><strong>{{hintStart}}</strong> </mat-hint>
    <mat-hint align="end">{{hintEnd}}</mat-hint>
    <mat-error *ngIf="formControl.hasError('pattern')">
        {{ 'not_decimal' | translate }}
    </mat-error>
    <mat-error *ngIf="formControl.hasError('required')">
         {{ 'field_required' | translate }}
    </mat-error>
</mat-form-field>

<!-- Type Phone -->
<mat-form-field *ngIf="type === 'phone'" class="app-input" appearance="outline">
    <mat-label>{{label}}</mat-label>
    <input matInput placeholder="{{placeholder}}" maxlength="255" [formControl]="formControl"
        required="{{required}}" type="number" readonly="{{readonly}}" [ngModel]="property"
        (ngModelChange)="changeModel($event)">
    <mat-hint align="start"><strong>{{hintStart}}</strong> </mat-hint>
    <mat-hint align="end">{{hintEnd}}</mat-hint>
    <mat-error *ngIf="formControl.hasError('required')">
         {{ 'field_required' | translate }}
    </mat-error>
</mat-form-field>

<!-- Type textarea -->
<mat-form-field *ngIf="type === 'textArea'" class="app-input textarea" [ngClass]="{'read-only': readonly}" appearance="outline">
    <mat-label>{{label}}</mat-label>
    <textarea matInput #textArea placeholder="{{placeholder}}" cdkTextareaAutosize cdkAutosizeMinRows="3" [maxlength]="maxLength" [formControl]="formControl"
        required="{{required}}" type="{{type}}" readonly="{{readonly}}" [ngModel]="property"
        (ngModelChange)="changeModel($event)"></textarea>
    <mat-hint align="start"><strong>{{hintStart}}</strong> </mat-hint>
    <mat-hint align="end">{{textArea.value.length}} / {{maxLength}} max</mat-hint>
    <mat-error *ngIf="formControl.hasError('required')">
         {{ 'field_required' | translate }}
    </mat-error>
</mat-form-field>

<!-- Type date -->
<mat-form-field *ngIf="type === 'date'" class="app-input" appearance="outline" [floatLabel]="'always'" [ngClass]="{'small' : small}">
    <mat-label *ngIf="label">{{label}}</mat-label>
    <input matInput placeholder="DD/MM/YYYY" [matDatepicker]="picker" [formControl]="formControl"
        (click)="!readonly && picker.open()" required="{{required}}" readonly="{{readonly}}" [ngModel]="property" [max]="maxDate"
        (ngModelChange)="changeModel($event)" (dateChange)="onDateChange($event)">
    <mat-datepicker-toggle *ngIf="!readonly" matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <mat-hint align="start"><strong>{{hintStart}}</strong> </mat-hint>
    <mat-hint align="end">{{hintEnd}}</mat-hint>
    <mat-error *ngIf="formControl.hasError('required')">
         {{ 'field_required' | translate }}
    </mat-error>
</mat-form-field>

<!-- Type datemonth -->
<mat-form-field *ngIf="type === 'datemonth'" class="app-input" appearance="outline" [floatLabel]="'always'" appMonthYearFormat>
    <mat-label>{{label}}</mat-label>
    <input matInput placeholder="MM/YYYY" [matDatepicker]="picker" [formControl]="formControl"
        required="{{required}}" readonly="{{readonly}}" [ngModel]="property" [max]="maxDate"
        (ngModelChange)="changeModel($event)">
    <mat-datepicker-toggle *ngIf="!readonly" matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker startView="multi-year" (monthSelected)="setMonthAndYear($event, picker)"></mat-datepicker>
    <mat-hint align="start"><strong>{{hintStart}}</strong> </mat-hint>
    <mat-hint align="end">{{hintEnd}}</mat-hint>
    <mat-error *ngIf="formControl.hasError('required')">
         {{ 'field_required' | translate }}
    </mat-error>
</mat-form-field>

<!-- Type range -->
<mat-form-field *ngIf="type === 'range'" class="app-input" appearance="outline">
    <mat-label>{{label}}</mat-label>
    <mat-date-range-input [rangePicker]="picker">
        <input matStartDate placeholder="Start date" [formControl]="formControl" [ngModel]="property"
            (ngModelChange)="changeModel($event)" required="{{required}}" readonly="true">
        <input matEndDate placeholder="End date" [formControl]="formControl">
    </mat-date-range-input>
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
    <mat-hint align="start"><strong>{{hintStart}}</strong> </mat-hint>
    <mat-hint align="end">{{hintEnd}}</mat-hint>
    <mat-error *ngIf="formControl.hasError('required')">
         {{ 'field_required' | translate }}
    </mat-error>
</mat-form-field>

<!-- Type Address -->
<mat-form-field *ngIf="type === 'address'" class="app-input" [ngClass]="{'read-only': readonly}" appearance="outline">
    <mat-label>{{label}}</mat-label>
    <input matInput ngx-gplaces-autocomplete placeholder="{{placeholder}}" [maxlength]="maxLength" [minlength]="minLength" [formControl]="formControl"
        required="{{required}}" type="{{type}}" readonly="{{readonly}}" [ngModel]="property"
        (ngModelChange)="changeModel($event)">
    <mat-hint align="start"><strong>{{hintStart}}</strong> </mat-hint>
    <mat-hint align="end">{{hintEnd}}</mat-hint>
    <mat-error *ngIf="formControl.hasError('required')">
         {{ 'field_required' | translate }}
    </mat-error>
</mat-form-field>

<!-- Type Search -->
<mat-form-field *ngIf="type === 'search'" class="app-input search" [ngClass]="{'read-only': readonly}" [floatLabel]="readonly ? 'always' : 'auto'" appearance="outline">
    <!-- <mat-label>{{label}}</mat-label> -->
    <button *ngIf="!searchButton" matPrefix mat-icon-button style="cursor: text;" [ngClass]="{'active': property && property.length >= 1}">
        <mat-icon>search</mat-icon>
    </button>
    <button *ngIf="searchButton" matPrefix mat-icon-button (click)="onSearchClick()" class="app-button stroked no-border small-icon-button" [ngClass]="{'active': property && property.length >= 1}" style="pointer-events: all; margin-left:12px">
        <mat-icon>search</mat-icon>
    </button>
    <input matInput placeholder="{{placeholder}}" [maxlength]="maxLength" [minlength]="minLength" [formControl]="formControl"
        required="{{required}}" type="text" readonly="{{readonly}}" [ngModel]="property"
        (ngModelChange)="changeModel($event)" (input)="changeInput($event)" (keydown.enter)="searchButton && onSearchClick()">
    <button *ngIf="property && property.length > 1" matSuffix mat-icon-button (click)="onClearField()" [ngClass]="{'active': property && property.length >= 1}">
        <mat-icon>close</mat-icon>
    </button>
    <mat-hint align="start"><strong>{{hintStart}}</strong> </mat-hint>
    <mat-hint align="end">{{hintEnd}}</mat-hint>
    <mat-error *ngIf="formControl.hasError('required')">
         {{ 'field_required' | translate }}
    </mat-error>
</mat-form-field>

<!-- Type Piva -->
<mat-form-field *ngIf="type === 'piva'" class="app-input" appearance="outline">
    <mat-label>{{label}}</mat-label>
    <button matTooltip="{{ 'autocomplete_piva_message' | translate }}" (click)="onSearchClick()" mat-icon-button matSuffix class="app-button stroked no-border small-icon-button" [disabled]="property?.length <= 8" style="pointer-events: all; margin-right:12px">
        <mat-icon>search</mat-icon>
    </button>
    <input matInput placeholder="{{placeholder}}" [maxlength]="maxLength" [minlength]="minLength" [formControl]="formControl"
        required="{{required}}" type="text" [ngModel]="property" (keydown.enter)="onSearchClick()"
        (ngModelChange)="changeModel($event)">
    <mat-hint align="start"><strong>{{hintStart}}</strong> </mat-hint>
    <mat-hint align="end">{{hintEnd}}</mat-hint>
    <mat-error *ngIf="formControl.hasError('required')">
         {{ 'field_required' | translate }}
    </mat-error>
</mat-form-field>

<!-- Type searchinput -->
<mat-form-field *ngIf="type === 'searchinput'" class="app-input" appearance="outline" [ngClass]="{'read-only': readonly}">
    <mat-label>{{label}}</mat-label>
    <button *ngIf="!hideSearch" (click)="onSearchClick()" mat-icon-button matSuffix class="app-button stroked no-border small-icon-button" [disabled]="disabledSearch" style="pointer-events: all; margin-right:12px">
        <mat-icon>search</mat-icon>
    </button>
    <input matInput placeholder="{{placeholder}}" [maxlength]="maxLength" [minlength]="minLength" [formControl]="formControl"
        required="{{required}}" type="text" [ngModel]="property" (keydown.enter)="onSearchClick()"
        (ngModelChange)="changeModel($event)">
    <mat-hint align="start"><strong>{{hintStart}}</strong> </mat-hint>
    <mat-hint align="end">{{hintEnd}}</mat-hint>
    <mat-error *ngIf="formControl.hasError('required')">
         {{ 'field_required' | translate }}
    </mat-error>
</mat-form-field>

<!-- Type InputOnly -->
<div *ngIf="type === 'only-input'">
    <input  class="only-input" matInput placeholder="{{placeholder}}"
    [maxlength]="maxLength" [minlength]="minLength" [formControl]="formControl"
    required="{{required}}" type="text" readonly="{{readonly}}" [ngModel]="property"
    (ngModelChange)="changeModel($event)">
    <mat-error *ngIf="formControl.hasError('required')">
         {{ 'field_required' | translate }}
    </mat-error>
</div>

<!-- Type Time -->
<mat-form-field *ngIf="type === 'time'" class="app-input time-picker" [ngClass]="{'read-only': readonly}" [floatLabel]="readonly ? 'always' : 'auto'" [appearance]="appearance">
    <mat-label>{{label}}</mat-label>
    <mat-icon class="app-input-suffix" *ngIf="matSuffix" matSuffix>{{matSuffix}}</mat-icon>
    <input matInput placeholder="{{placeholder}}" [maxlength]="maxLength" [minlength]="minLength" [formControl]="formControl"
        required="{{required}}" type="{{type}}" readonly="{{readonly}}" [ngModel]="property"
        (ngModelChange)="changeModel($event)">
    <mat-hint align="start"><strong>{{hintStart}}</strong> </mat-hint>
    <mat-hint align="end">{{hintEnd}}</mat-hint>
    <mat-error *ngIf="formControl.hasError('required')">
         {{ 'field_required' | translate }}
    </mat-error>
    <mat-error *ngIf="formControl.hasError('minlength') && !formControl.hasError('required')">
        {{ 'Min ' + minLength + ' ' +
        ('characters' | translate) }}
    </mat-error>
    <mat-error *ngIf="formControl.hasError('maxlength') && !formControl.hasError('required')">
        {{ 'Max ' + maxLength + ' ' +
        ('characters' | translate)}}
    </mat-error>
</mat-form-field>

<!-- Type Search dashboard style -->
<mat-form-field *ngIf="type === 'searchbar'" class="app-input-searchbar search" [ngClass]="{'read-only': readonly}" [floatLabel]="readonly ? 'always' : 'auto'" appearance="outline">
  <!-- <mat-label>{{label}}</mat-label> -->
  <button matPrefix mat-icon-button style="cursor: text;" [ngClass]="{'active': property && property.length >= 1}">
      <mat-icon>search</mat-icon>
  </button>
  <input matInput placeholder="{{placeholder}}" [maxlength]="maxLength" [minlength]="minLength" [formControl]="formControl"
      required="{{required}}" type="text" readonly="{{readonly}}" [ngModel]="property"
      (ngModelChange)="changeModel($event)">
  <button *ngIf="property && property.length > 1" matSuffix mat-icon-button (click)="onClearField()" [ngClass]="{'active': property && property.length >= 1}">
      <mat-icon>close</mat-icon>
  </button>
  <mat-hint align="start"><strong>{{hintStart}}</strong> </mat-hint>
  <mat-hint align="end">{{hintEnd}}</mat-hint>
  <mat-error *ngIf="formControl.hasError('required')">
       {{ 'field_required' | translate }}
  </mat-error>
</mat-form-field>
