import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { UtilService } from 'src/app/services/util.service';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogData } from 'src/app/models/DialogData';
import { Role } from 'src/app/models/Role';
import { DataService } from 'src/app/services/data.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-role-table',
  templateUrl: './role-table.component.html',
  styleUrls: ['./role-table.component.scss']
})
export class RoleTableComponent {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  pageIndex = 0;
  itemsCount = 0;
  @Input() list: Role[] = [];
  @Input() pageSize = 5;
  @Input() enableSelection = false;
  @Input() enableFilters = false;
  @Input() stickyHeader = false;
  @Input() customTableHeight = '56px';
  @Output() changedEvent = new EventEmitter<boolean>();

  displayedColumns: string[] = ['id', 'name', 'permission_number', 'actionsdetail'];
  dataSource!: MatTableDataSource<Role>;
  selection = new SelectionModel<Role>(true, []);
  showPaginator = true;

  constructor(
    public utilService: UtilService,
    public matPaginatorIntl: MatPaginatorIntl,
    private router: Router,
    public dialog: MatDialog,
    private dataService: DataService,
    public authService: AuthService
  ) {
    this.translateMatPaginator();
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    if (this.enableSelection) {
      this.displayedColumns.unshift('select');
    }
    this.load();
  }

  async load() {
    this.utilService.showSpinner();
    const response = await this.dataService.getRoles(this.pageIndex * this.pageSize, this.pageSize);
    if (response) {
      this.list = response.dto;
      this.itemsCount = response.count;
      this.showPaginator = this.itemsCount > 10;
      this.dataSource = new MatTableDataSource(this.list);
      // this.dataSource.paginator = this.paginator;
      // this.dataSource.sort = this.sort;
    }
    this.utilService.hideSpinner();
  }

  isSystemRole(role) {
    if (role) {
      if (role.name === 'Admin' || role.name === 'Fornitore' || role.name === 'Affiliato' || role.name === 'Responsabile Tecnico') {
        return true;
      }
    }
    return false;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  goToDetail(element: Role) {
    this.router.navigate(['role', element.id]);
  }

  goToEdit(element: Role) {
    this.router.navigate(['role', element.id, 'edit']);
  }

  goToCreate() {
    this.router.navigate(['role', 'create']);
  }

  async deleteElement(element: Role) {
    const dialogConfig = new DialogData();
    dialogConfig.title = this.utilService.translate.instant('delete_role');
    dialogConfig.message = this.utilService.translate.instant('delete_confirm_message');
    dialogConfig.showCancelButton = true;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { data: dialogConfig });
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        this.utilService.showSpinner();
        const deleted = await this.dataService.deleteRole(element);
        this.utilService.hideSpinner();
        if (deleted) {
          this.load();
        }
      }
    });
  }

  handlePageEvent(pageEvent: PageEvent) {
    this.pageIndex = pageEvent.pageIndex;
    this.selection.clear();
    this.load();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  translateMatPaginator() {
    this.matPaginatorIntl.itemsPerPageLabel = this.utilService.translate.instant('items_per_page');
    this.matPaginatorIntl.nextPageLabel = this.utilService.translate.instant('next_page');
    this.matPaginatorIntl.previousPageLabel = this.utilService.translate.instant('previous_page');
    this.matPaginatorIntl.firstPageLabel = this.utilService.translate.instant('first_page');
    this.matPaginatorIntl.lastPageLabel = this.utilService.translate.instant('last_page');
    this.matPaginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return `0 ${this.utilService.translate.instant('of')} ${length}`;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex} ${this.utilService.translate.instant('of')} ${length}`;
    };
  }
}
